import { Box, Popover, Typography, styled } from '@mui/material';
import { FunctionComponent, ReactNode, useRef, useState } from 'react';
import capitalize from 'lodash/fp/capitalize';
import { Button } from '@/components/atoms';
import CheckoutForm from '@/components/organisms/CheckoutForm';
import { useUserSubscriptions } from '@/modules/user/useUserSubscriptions';

export interface ManageBillingMenuProps {
  target?: string;
  hotkeys?: string[];
  title: string | ReactNode;
  id: string;
  hidden?: boolean;
}

const MenuItem = styled(Button)(({ theme }) => ({
  flex: 1,
  justifyContent: 'flex-start',
  textAlign: 'left',
  width: '100%',

  [theme.breakpoints.down('sm')]: {
    height: theme.spacing(3.5),
  },

  '&:hover': {
    textDecoration: 'underline',
  },
}));

const Menu = styled(Popover)(({ theme }) => ({
  zIndex: theme.zIndex.modal + 1,
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
  minWidth: theme.spacing(10),
}));

const ManageBillingMenu: FunctionComponent<ManageBillingMenuProps> = ({
  title,
  ...item
}) => {
  const { data: subscriptions } = useUserSubscriptions();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);

  return (
    <>
      {(subscriptions?.filter((subscription) => subscription.role !== 'member')
        ?.length || 0) > 1 ? (
        <>
          <MenuItem ref={ref} variant="text" {...item}>
            <Typography
              variant="body2"
              color="text.medium"
              onClick={() => setOpen(true)}
            >
              {title}
            </Typography>
          </MenuItem>
          <Menu
            open={open}
            anchorEl={ref.current}
            onClose={() => setOpen(false)}
          >
            <Container display="flex" flexDirection="column" gap={1}>
              {subscriptions?.map((subscription) => (
                <CheckoutForm style={{ width: '100%' }} customerPortal>
                  <Button variant="text" type="submit">
                    <Typography variant="body2" color="text.medium">
                      {capitalize(subscription.teamName || subscription.name)}
                    </Typography>
                  </Button>
                </CheckoutForm>
              ))}
            </Container>
          </Menu>
        </>
      ) : (
        <CheckoutForm style={{ width: '100%' }} customerPortal>
          <MenuItem variant="text" type="submit" {...item}>
            <Typography variant="body2" color="text.medium">
              {title}
            </Typography>
          </MenuItem>
        </CheckoutForm>
      )}
    </>
  );
};

export default ManageBillingMenu;
