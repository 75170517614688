import { FunctionComponent } from 'react';
import { Box, styled, Typography, IconButton } from '@mui/material';
import { EditRounded } from '@mui/icons-material';

import { useCloseDialog, useSetDialog } from '../state/dialog';
import { useUser } from '../state/user';
import Avatar from '../ui/Avatar';
import UpgradeButton from '@/components/molecules/UpgradeButton';

export interface UserSettingsProps {
  handleOpenSettingsMenu?: (value: boolean) => void;
  useStandaloneDialogs?: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 2, 1.5, 2),
  alignItems: 'flex-end',
  borderBottom: `1.5px solid ${theme.palette.line?.disabled}`,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1.5),
  width: '100%',
  flexWrap: 'wrap',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
    gap: theme.spacing(1),
  },
}));

const PrimaryContainer = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    alignItems: 'flex-start',
  },
}));

const Email = styled(Typography)(({ theme }) => ({
  color: theme.palette.helper,
}));

const Info = styled(Box)(({ theme }) => ({
  flexDirection: 'column',
  marginLeft: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const UserSettings: FunctionComponent<UserSettingsProps> = ({
  handleOpenSettingsMenu,
  useStandaloneDialogs = false,
}) => {
  const setDialog = useSetDialog();
  const closeDialog = useCloseDialog();
  const user = useUser();

  const handleEditButton = () => {
    if (handleOpenSettingsMenu) {
      handleOpenSettingsMenu(false);
      closeDialog();
      setDialog({ dialog: 'userProfile' });
    }
  };

  return (
    <Container>
      <Box display="flex" gap={1} justifyContent="space-between" width="100%">
        <PrimaryContainer>
          <Avatar user={user} />
          <Info>
            <Name variant="subtitle1">{user?.name}</Name>
            <Email variant="body2">{user?.email}</Email>
          </Info>
        </PrimaryContainer>
        {useStandaloneDialogs ? (
          <IconButton href="/app/manage/profile">
            <EditRounded color="high" />
          </IconButton>
        ) : (
          <IconButton onClick={handleEditButton}>
            <EditRounded color="high" />
          </IconButton>
        )}
      </Box>
      {user && (
        <UpgradeButton
          variant="primary"
          onClick={() => handleOpenSettingsMenu?.(false)}
        />
      )}
    </Container>
  );
};

export default UserSettings;
