import { useQuery } from 'react-query';
import { UserAPI } from '@/rpc/users';

const userAPI = new UserAPI();

export const useUserSubscriptions = () =>
  useQuery({
    queryKey: ['getSubscriptions'],
    queryFn: () => userAPI.getSubscriptions(),
  });
