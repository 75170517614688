import { forwardRef, HTMLProps } from 'react';
import { useUser } from '@/ext/app/state/user';

export interface CheckoutFormProps extends HTMLProps<HTMLFormElement> {
  customerPortal?: boolean;
  onSubmit?: () => void;
}

export const PRICE_ID_FIELD_ID = 'checkout-price-id';
export const TEAM_ID_FIELD_ID = 'checkout-team-id';
export const ADDITIONAL_LICENSE_QUANTITY_FIELD_ID =
  'additional-license-quantity';
export const ADDITIONAL_LICENSE_PRICE_ID_FIELD_ID =
  'additional-license-price-id';
export const CHECKOUT_FORM_ID = 'checkout-form';
export const SUCCESS_URL_FIELD_ID = 'success-url';

const CheckoutForm = forwardRef<HTMLFormElement, CheckoutFormProps>(
  ({ children, onSubmit, customerPortal, ...props }, ref) => {
    const user = useUser();

    const action = customerPortal
      ? '/v1/payments/customer-portal'
      : '/v1/payments/checkout';

    if (!user) {
      return <>{children}</>;
    }

    return (
      <form
        {...props}
        ref={ref}
        action={`${process.env.NEXT_PUBLIC_API_BASE}${action}`}
        onSubmit={onSubmit}
        method="POST"
        id={CHECKOUT_FORM_ID}
      >
        <input hidden name="priceId" id={PRICE_ID_FIELD_ID} />
        <input
          hidden
          name="successUrl"
          id={SUCCESS_URL_FIELD_ID}
          value={typeof window !== 'undefined' ? window.location.href : ''}
        />
        <input
          hidden
          name="additionalLicenseQuantity"
          id={ADDITIONAL_LICENSE_QUANTITY_FIELD_ID}
        />
        <input
          hidden
          name="additionalLicensePriceId"
          id={ADDITIONAL_LICENSE_PRICE_ID_FIELD_ID}
        />
        <input hidden name="cancelUrl" value={window.location.href} />
        <input hidden name="teamId" id={TEAM_ID_FIELD_ID} type="number" />
        {children}
      </form>
    );
  },
);

export default CheckoutForm;
